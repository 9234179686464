import React, { useState } from "react";
import PropTypes from "prop-types";
import media from "styled-media-query";
import styled from "styled-components";
import Flex from "app/components/Flex";
import { P1, P2 } from "app/components/Typography";
import { DATA_TEST_ID, LARGE_BREAKPOINT } from "constants/index";

const Wrapper = styled(Flex)`
  flex-direction: column;
  align-items: center;
  margin: 20px 0;
  cursor: pointer;
  border-radius: 8px;
  background-color: #f9f6f2;
  padding: 24px 40px;

  ${({ theme, selected }) =>
    selected &&
    `
    color: ${theme.colors.white};
    background-color: ${theme.colors.blue};
  `};

  ${media.lessThan(LARGE_BREAKPOINT)`
    padding: 14px 20px;
  `}
`;

export function Option({ label, description, onSelect, slug }) {
  const [selected, setSelected] = useState(false);
  return (
    <Wrapper
      data-test-id={DATA_TEST_ID.onboarding.optionWrapper}
      width={{ _: "100%", lg: "519px" }}
      height={{ _: "86px", md: "100px", lg: "116px" }}
      selected={selected}
      onClick={() => {
        onSelect();
        setSelected(true);
      }}
    >
      <Flex
        width="100%"
        height="100%"
        data-test-id={DATA_TEST_ID.onboarding.optionSlug(slug)}
        flexDirection="column"
        justifyContent="center"
        letter-spacing="0.01em"
        lineHeight="140%"
      >
        <P1
          data-test-id={DATA_TEST_ID.onboarding.optionTitle}
          mb={{ _: 1, lg: "12px" }}
          fontWeight="bold"
          fontSize={{ _: "14px", lg: "16px" }}
        >
          {label}
        </P1>
        <P2 fontSize={{ _: "12px", lg: "14px" }}>{description}</P2>
      </Flex>
    </Wrapper>
  );
}

Option.propTypes = {
  label: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
  slug: PropTypes.string.isRequired,
  onSelect: PropTypes.func.isRequired,
};
